<template>
	<v-flex
		xs12
		sm6
		md4
		lg6
		class="pa-4 elevation-1"
		style="min-height: 100vh; background-color: #ebeff3"
	>
		<div class="elevation-3">
			<div class="d-flex pa-2 border border-bottom elevation-1 white lighten-5">
				<v-icon large color="light-blue lighten-3"> mdi-web </v-icon>
				<div class="d-flex flex-column ml-2">
					<div>Web Chat- Chat with us</div>

					<v-tooltip top>
						<template v-slot:activator="{ on, attrs }">
							<div v-bind="attrs" v-on="on" class="blue--text lighten-5" style="position: relative">
								<v-menu offset-y :close-on-content-click="false">
									<template v-slot:activator="{ on, attrs }">
										<span color="primary" dark v-bind="attrs" v-on="on"> Mohd Uvesh </span>
									</template>
									<div class="p-2 white" style="width: 300px">
										<v-text-field
											placeholder="Search agent"
											dense
											class="px-2 p-0 m-0"
											prepend-icon="mdi-magnify"
										></v-text-field>
										<div class="d-flex justify-space-between blue lighten-5 p-2">
											<v-avatar size="30" color="blue lighten-3 blue--text"> M </v-avatar>
											<div class="mx-3 d-flex flex-column">
												<span class="font-weight-bold text-h-6 blue-grey--text darken-4">Mohd Uvesh</span>
												<span>uvesh@businessthurst.com</span>
											</div>
											<v-icon size="20" color="blue 5">mdi-check</v-icon>
										</div>
									</div>
								</v-menu>
							</div>
						</template>
						<span>Mohd Uvesh</span>
						<span class="d-block">uvesh@businessthurst.com</span>
					</v-tooltip>
				</div>
			</div>
			<div class="pa-4 cyan lighten-5" style="height: 560px; overflow: scroll">
				<div class="d-flex justify-space-between py-8">
					<v-divider class="mx-4"></v-divider>
					Yesterday
					<v-divider class="mx-4"></v-divider>
				</div>
				<div class="d-flex flex-column">
					<div class="d-flex justify-end align-end flex-column">
						<span>Mohd Uvesh</span>

						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<span
									v-bind="attrs"
									v-on="on"
									class="white pa-1 my-1 d-flex flex-column align-end rounded-sm"
								>
									This is an example conversation. Just to give you an idea on how a conversation will look
									like
									<v-icon x-small color="light-grey lighten-3  " class="mt-n1 mt-1"> mdi-check </v-icon>
								</span>
							</template>
							<span class="blue-grey white--text darken-4 font-weight-normal">feb 8,2023 9:45 AM</span>
						</v-tooltip>
						<span class="font-italic body-2">9:45 AM</span>
					</div>
					<div class="d-flex my-8">
						<v-menu open-on-hover top offset-y>
							<template v-slot:activator="{ on, attrs }">
								<v-avatar size="34" v-bind="attrs" v-on="on" class="mr-2 red lighten-3">
									<span class="font-weight-bold red--text lighten darken-5">CJ</span>
								</v-avatar>
							</template>
							<div class="white pa-4">
								<div class="d-flex white">
									<v-avatar size="28" color="red">
										<span class="white--text text-h5">CJ</span>
									</v-avatar>
									<div class="pl-2">
										<span class="text-h6 font-weight-bold">Jane Sampleton (Sample)</span>
										<div><v-icon>mdi-map-marker</v-icon> Not available</div>
									</div>
								</div>
								<div class="mx-4">
									<v-divider class="mx-1"></v-divider>
									<div>Email<span class="blue--text ml-4">:janesampleton@gmail.com</span></div>
									<div class="my-3">Phone<span class="black--text ml-4">Not available</span></div>
								</div>
							</div>
						</v-menu>
						<div class="d-flex flex-column">
							<div class="blue--text lighten-3">Jane Sampleton (Sample)</div>

							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<div
										v-bind="attrs"
										v-on="on"
										class="blue d-inline-block lighten-3 rounded-r-xl rounded-bl-xl pa-2 my-1 rounded-sm chatbox"
									>
										Lucky for me her birthday is still a week out.
									</div>
								</template>
								<span>feb 8,2023 9:45 AM</span>
							</v-tooltip>
							<div class="font-italic body-2">9:45 AM</div>
						</div>
					</div>
					<div class="d-flex justify-end align-end flex-column">
						<span>Mohd Uvesh</span>
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<span
									v-bind="attrs"
									v-on="on"
									class="white pa-1 my-1 d-flex flex-column align-end rounded-sm"
								>
									I’ll raise a return request for you.

									<v-icon x-small color="light-grey lighten-3  " class="mt-n1 mt-1"> mdi-check </v-icon>
								</span>
							</template>
							<span class="blue-grey white--text darken-4 font-weight-normal">feb 8,2023 9:45 AM</span>
						</v-tooltip>

						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<span
									v-bind="attrs"
									v-on="on"
									class="white pa-1 my-1 d-flex flex-column align-end rounded-sm"
								>
									This is an example conversation. Just to give you an idea on how a conversation will look
									like
									<v-icon x-small color="light-grey lighten-3  " class="mt-n1 mt-1"> mdi-check </v-icon>
								</span>
							</template>
							<span class="blue-grey white--text darken-4 font-weight-normal">feb 8,2023 9:45 AM</span>
						</v-tooltip>
						<span class="font-italic body-2">9:45 AM</span>
					</div>
					<div class="d-flex my-8">
						<v-menu open-on-hover top offset-y>
							<template v-slot:activator="{ on, attrs }">
								<v-avatar size="34" v-bind="attrs" v-on="on" class="mr-2 red lighten-3">
									<span class="font-weight-bold red--text lighten darken-5">CJ</span>
								</v-avatar>
							</template>
							<div class="white pa-4">
								<div class="d-flex white">
									<v-avatar size="28" color="red">
										<span class="white--text text-h5">CJ</span>
									</v-avatar>
									<div class="pl-2">
										<span class="text-h6 font-weight-bold">Jane Sampleton (Sample)</span>
										<div><v-icon>mdi-map-marker</v-icon> Not available</div>
									</div>
								</div>
								<div class="mx-4">
									<v-divider class="mx-1"></v-divider>
									<div>Email<span class="blue--text ml-4">:janesampleton@gmail.com</span></div>
									<div class="my-3">Phone<span class="black--text ml-4">Not available</span></div>
								</div>
							</div>
						</v-menu>
						<div class="d-flex flex-column">
							<div class="blue--text lighten-3">Jane Sampleton (Sample)</div>

							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<div
										v-bind="attrs"
										v-on="on"
										class="blue d-inline-block lighten-3 rounded-r-xl rounded-bl-xl pa-2 my-1 rounded-sm chatbox"
									>
										Lucky for me her birthday is still a week out.
									</div>
								</template>
								<span>feb 8,2023 9:45 AM</span>
							</v-tooltip>
							<div class="font-italic body-2">9:45 AM</div>
						</div>
					</div>
					<div class="d-flex justify-end align-end flex-column">
						<span>Mohd Uvesh</span>
						<span class="white pa-1 my-1 d-flex flex-column align-end rounded-sm yellow lighten-5">
						</span>
						<span class="font-italic body-2">9:45 AM</span>
					</div>
					<p class="text-center">
						Conversation was assigned to <span class="font-weight-bold">Mohd Uvesh </span> by Mohd Uvesh
					</p>
				</div>
			</div>
			<v-card style="max-height: 100vh" color="basil" class="p-0">
				<v-tabs v-model="tab" color="blue darken-4">
					<v-tab v-for="item in items" :key="item" class="body-1">
						{{ item }}
					</v-tab>
				</v-tabs>

				<v-tabs-items v-model="tab" class="pa-0">
					<v-tab-item class="pa-0 m-0">
						<v-card color="basil">
							<v-card-text>
								<v-textarea
									rows="4"
									row-height="10"
									class="pa-0"
									solo
									v-model="chatInput"
									label="Shift + Enter to add  a new line .Start with '/'  to select a canned Response "
								>
								</v-textarea
							></v-card-text>
						</v-card>
					</v-tab-item>
					<v-tab-item>
						<v-card color="basil" flat>
							<v-card-text>
								<v-textarea
									rows="4"
									row-height="10"
									background-color="amber lighten-5"
									solo
									label="Private notes are visible to you and your team'/'  to select a canned Response "
								></v-textarea
							></v-card-text>
						</v-card>
					</v-tab-item>
				</v-tabs-items>
				<div v-if="file" class="my-2 d-flex justify-space-between px-4">
					<div>
						<v-icon>mdi-image-outline</v-icon>
						<span class="mx-3">{{ file.name }}</span>
						<span>({{ uploadImageSize }} KB)</span>
					</div>
					<div class="delete-icon rounded" @click="deleteUploadImages">
						<v-icon class="p-0" small>mdi-delete-outline</v-icon>
					</div>
				</div>
				<div class="px-4 pt-4 pb-2 d-flex justify-space-between">
					<div class="d-flex">
						<v-file-input
							hide-details
							clearable
							v-model="file"
							hide-input
							truncate-length="5"
							v-on:change="updateFile(index, $event)"
						></v-file-input>
						<v-file-input
							hide-details
							clearable
							v-model="file"
							prepend-icon="mdi-image-outline"
							hide-input
							truncate-length="5"
							v-on:change="updateFile(index, $event)"
						></v-file-input>

						<emoji-picker @emoji="append" :search="search">
							<button
								class="emoji-invoker"
								slot="emoji-invoker"
								slot-scope="{ events: { click: clickEvent } }"
								@click.stop="clickEvent"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon>mdi-emoticon-outline</v-icon>
							</button>
							<div
								slot="emoji-picker"
								slot-scope="{ emojis, insert, display }"
								style="max-width: 250px; height: 250px; overflow: scroll; position: absolute; bottom: 40px"
								class="white pa-4 elevation-3 rounded-sm"
							>
								<div class="emoji-picker" :style="{ top: display.y + 'px', left: display.x + 'px' }">
									<div class="emoji-picker__search">
										<!-- <input  /> -->
										<!-- <v-text-field  type="text" label="Outlined"></v-text-field> -->
										<v-text-field label="Search Emoji" outlined v-model="search" v-focus dense></v-text-field>
									</div>
									<div>
										<div v-for="(emojiGroup, category) in emojis" :key="category">
											<h5>{{ category }}</h5>
											<div class="emojis">
												<span
													v-for="(emoji, emojiName) in emojiGroup"
													:key="emojiName"
													style="font-size: 16px; cursor: pointer"
													@click="insert(emoji)"
													:title="emojiName"
													>{{ emoji }}</span
												>
											</div>
										</div>
									</div>
								</div>
							</div>
						</emoji-picker>
					</div>
					<div>
						<v-btn :disabled="!chatInput" class="blue-grey darken-3 white--text">
							<v-icon small left> mdi-send </v-icon>
							Send
						</v-btn>
					</div>
				</div>
			</v-card>
		</div>
	</v-flex>
</template>
<script>
/* import EmojiPicker from "vue-emoji-picker"; */
export default {
	name: "support-chat",
	title: "support Project",
	components: {
		/* EmojiPicker, */
	},
	// created: function () {
	// 	window.addEventListener("mouseup", function (event) {
	// 		var pol = document.getElementById("pol");
	// 		if (event.target != "pol" && event.target.parentNode != "pol") {
	// 			pol.style.display = "none";
	// 		}
	// 	});
	// },
	// destroyed: function () {
	// 	window.addEventListener("mouseup", function (event) {
	// 		var pol = document.getElementById("pol");
	// 		if (event.target !== pol && event.target.parentNode !== pol) {
	// 			pol.style.display = "none";
	// 		}
	// 	});
	// },
	data: () => ({
		tab: null,
		items: ["Reply", "Private Notes"],
		text: "lorem ;lorm",
		chatInput: "",
		file: "",
		input: "",
		search: "",
	}),
	computed: {
		uploadImageSize() {
			let result = this.file.size / 1000;
			return result.toFixed(1);
		},
	},
	methods: {
		updateFile(index, file) {
			this.chatInput = file.name;
		},
		deleteUploadImages() {
			this.chatInput = "";
			this.file = "";
		},
		append(emoji) {
			this.chatInput += emoji;
		},
	},
	directives: {
		focus: {
			inserted(el) {
				el.focus();
			},
		},
	},
};
</script>
<style scoped>
.border-bottom {
	border-bottom: 2px solid grey;
}
.emoji-picker {
	height: 250px;
}
.border1 {
	/* border: 2px solid red; */
}
.v-card__subtitle,
.v-card__text,
.v-card__title {
	padding: 0px;
}
.v-text-field {
	padding-top: 0px;
	margin-top: 0px;
}
.v-text-field__details {
	min-height: 0px;
}
.v-messages {
	display: none;
}
.delete-icon {
	border: 1px solid grey;
	cursor: pointer;
}
.delete-icon:hover {
	background-color: rgb(128, 128, 128, 0.2);
}
.v-application .pt-4 {
	padding-top: 0px !important;
}
</style>
