<template>
	<v-flex xs12 sm6 md3 class="pl-8 pa-4" style="background-color: #ebeff3">
		<v-expansion-panels v-model="panel" multiple>
			<v-expansion-panel @click="expansionPanelClick('first')">
				<v-expansion-panel-header
					active-class="blue lighten-1"
					class="justify-self-start cyan lighten-5 py-1 expansion-panel-header"
				>
					<div>
						<v-icon color="grey darken-2">mdi-account</v-icon>
						<span
							:class="[firstPanelOpen ? 'font-weight-bold text-body-6' : 'font-weight-medium']"
							class="ml-4"
							>User Info</span
						>
					</div>
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<div class="d-flex my-2">
						<v-avatar color="red lighten-4 " size="40" style="border: 2px solid red">MJ</v-avatar>
						<div class="ml-4 d-flex flex-column">
							<div class="mb-2">
								<span class="font-weight-bold text-body-1">Jane Sampleton(Sample..)</span>
							</div>
							<div class="font-weight-regular">
								<v-icon small class="mr-2">mdi-map-marker-outline</v-icon>Not Available
							</div>
							<div class="font-weight-regular my-1">
								<v-icon small class="mr-2">mdi-email-outline</v-icon>janesampleton@gmail.com
							</div>
							<div class="font-weight-regular">
								<v-icon small class="mr-2">mdi-phone-outline</v-icon>Not Available
							</div>
						</div>
					</div>
					<v-divider class="grey lighten-3"></v-divider>
					<div class="d-flex flex-column my-4">
						<span>Conversion initiated from</span>

						<v-tooltip top transition="none">
							<template v-slot:activator="{ on, attrs }">
								<span v-bind="attrs" v-on="on" class="blue--text lighten-5"
									>Apple iPhone Rose Gold 128 GB</span
								>
							</template>
							<span>Apple iPhone Rose Gold 128 GB</span>
						</v-tooltip>
					</div>
					<div class="d-flex flex-column">
						<span>Last message sent from</span>

						<v-tooltip top transition="none">
							<template v-slot:activator="{ on, attrs }">
								<span v-bind="attrs" v-on="on" class="blue--text lighten-5"
									>Apple iPhone Rose Gold 128 GB</span
								>
							</template>
							<span>Apple iPhone Rose Gold 128 GB</span>
						</v-tooltip>
					</div>
				</v-expansion-panel-content>
			</v-expansion-panel>
			<v-expansion-panel class="my-6" @click="expansionPanelClick('second')">
				<v-expansion-panel-header class="justify-self-start cyan lighten-5 py-1 expansion-panel-header">
					<div>
						<v-icon color="grey darken-2">mdi-account</v-icon>
						<span
							:class="[secondPanelOpen ? 'font-weight-bold text-body-6' : 'font-weight-medium']"
							class="ml-4"
							>Custom contact info</span
						>
					</div>
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<div class="d-flex justify-end">
						<v-icon small color="blue lighten-2">mdi-pencil</v-icon>
						<span class="font-weight-regular ml-1 blue--text lighten-2">Edit</span>
					</div>
					<v-divider class="grey lighten-3"></v-divider>
					<div class="grey--text d-flex justify-center align-center">No Data Available</div>
				</v-expansion-panel-content>
			</v-expansion-panel>
			<v-expansion-panel @click="expansionPanelClick('third')">
				<v-expansion-panel-header class="justify-self-start cyan lighten-5 py-1 expansion-panel-header">
					<div>
						<inline-svg style="width: 20px" :src="$assetURL('media/custom-svg/support.svg')" />
						<span
							:class="[thirdPanelOpen ? 'font-weight-bold text-body-6' : 'font-weight-medium']"
							class="ml-4"
							>Freshdesk Support Desk</span
						>
					</div>
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<div class="blue-grey--text darken-2 my-2">
						Don't ask your customers to repeat. Sync email conversations inside the inbox.
					</div>
					<div class="mt-4 light-blue--text darken-4">
						Configure
						<v-icon small class="light-blue--text darken-4">mdi-file-edit-outline</v-icon>
					</div>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</v-flex>
</template>
<script>
export default {
	name: "support-chat-details",
	title: "support-chat-details",
	data() {
		return {
			panel: [0, 1, 2],
			firstPanelOpen: true,
			secondPanelOpen: true,
			thirdPanelOpen: true,
		};
	},
	methods: {
		expansionPanelClick(no) {
			if (no === "first") {
				this.firstPanelOpen = !this.firstPanelOpen;
			} else if (no === "second") {
				this.secondPanelOpen = !this.secondPanelOpen;
			} else {
				this.thirdPanelOpen = !this.thirdPanelOpen;
			}
		},
	},
};
</script>
<style scoped>
.expansion-panel-header {
	transition: all 400ms ease-in-out;
}
</style>
